import React, {Component} from "react"

import Header from "./header"

class LayoutFullscreen extends Component {
  state = {
    loading: true
  };
 

  componentDidMount() {
    if (this.video) {
      this.video.addEventListener("loadeddata", () => {
        /* setTimeout(this.setState({ loading: false }), 3000); */
        this.setState({ loading: false });
      });
    }
  }

  componentWillUnmount() {
    if (this.video) {
      this.video.removeEventListener("loadeddata", () => {
      });
    }
  }

  render() {
    return (
      <div className="fullscreen-container">
      <Header className={this.props.className} />
      <video
        ref={ref => (this.video = ref)}
        autoPlay
        muted
        loop
        style={{
          position: "fixed",
          width: "100%",
          left: 0,
          top: 0,
          opacity: this.state.loading ? 0 : 1,
          transition: "opacity, 2s ease-in-out",
          zIndex: -2
        }}
        className="bg-video"
      >
        <source src="/Video-Background-Short.mp4" type="video/mp4" />
      </video>
      <div className="video-overlay"></div>
      {this.props.children}
      </div>
    );
  }
}

export default LayoutFullscreen