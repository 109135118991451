import React from "react"
import SEO from "../components/seo"
import LayoutFullscreen from "../components/layoutFullscreen"

/* const pageSEO = {title: "Benjamin Engeli", description: "SEO Meta Description"} */

export default function Home() {
  return (
    <LayoutFullscreen>
      <SEO />
      <div className="content">
        <h1>Benjamin Engeli</h1>
        <p><b>Benjamin Engeli</b> is one of the most versatile Swiss musicians of his generation. His career as a soloist, chamber musician and teacher has taken him to most European countries as well as to Australia, China, India, North and South America. </p>
        
        {/* <div className="button-container">
          <Link to="/music" className="button">Musik anhören</Link>
          <Link to="/concerts" className="button-alt">Konzert-Daten</Link>
        </div> */}

      </div>
    </LayoutFullscreen>
  )
}
